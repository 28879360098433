const passwordTranslations = {
  af: ['wagwoord'],
  ar: ['كلمه الس'],
  bn: ['পাসওয়ার্ড', 'পাসওয়ার্ড৷'],
  bg: ['парола'],
  'ca-es': ['contrasenya'],
  'zh-tw': ['密碼'],
  'zh-cn': ['密码'],
  hr: ['zaporka', 'lozinka'],
  cs: ['heslo', 'heslem'],
  da: ['adgangskode', 'kodeord'],
  nl: ['wachtwoord', 'paswoord'],
  en: ['password'],
  fi: ['salasana', 'tunnussana'],
  fr: ['mot de passe'],
  de: ['passwort', 'kennwort'],
  'el-gr': ['κωδικό πρόσβασης'],
  'he-il': ['סיסמה'],
  hu: ['jelszó'],
  it: ["parola d'ordine"],
  id: ['kata sandi'],
  ja: ['パスワード'],
  ko: ['비밀번호'],
  lt: ['slaptažodis'],
  ms: ['kata laluan'],
  no: ['passord'],
  pl: ['hasło'],
  pt: ['senha'],
  ro: ['parola', 'parolă'],
  ru: ['пароль'],
  sk: ['heslo'],
  sl: ['geslo'],
  es: ['clave', 'contraseña'],
  sv: ['lösenord'],
  th: ['รหัสผ่าน'],
  tr: ['şifre'],
  uk: ['пароль'],
  vi: ['mật khẩu mở khóa']
};
export const passwordTranslationsList = Object.values(passwordTranslations).flat();